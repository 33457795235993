@import 'shared';

.dialog-open {
  overflow: hidden;
}

.dialog {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.25);
  z-index: 5;

  &__outer {
    position: fixed;
    width: auto;
    max-height: 100%;
    width: 100%;
    right: 50%;
    left: 50%;
    top: 16px;
    bottom: 16px;
    transform: translate(-50%, 0);
    max-width: 1024px;
    display: flex;
    justify-content: center;

    @include media('>=desktop-navigation') {
      right: 50%;
      left: 50%;
      top: 24px;
      bottom: 24px;
    }

    &__body {
      z-index: 999;
      position: relative;
      width: auto;
      max-height: 100%;
      margin: auto 16px;
      display: flex;
      flex-direction: column;
      color: black;
      background-color: white;
      border: 1px solid rgba(204, 204, 204, 0.75);
      box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.1);
      border-radius: 8px;

      @include media('>=desktop-navigation') {
        margin: auto 24px;
      }

      &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid rgba(204, 204, 204, 0.75);

        > * {
          padding: 16px;
          margin: 0;
          line-height: 150%;
        }

        .closeButton {
          display: flex;
          align-items: center;
          font-weight: 700;
          font-size: 16px;
          height: 16px;
          margin-right: 8px;
          line-height: 150%;
          background: none;
          border: none;
          cursor: pointer;
          color: map-get($secondary, 500);
          img {
            height: 16px;
            padding-right: 8px;
          }
        }
      }

      &__content {
        padding: 16px;
        height: 100%;
        overflow-y: auto;
        color: map-get($black, 80);
      }
    }
  }
}
