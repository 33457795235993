// ---     ---------------------------
// Value   Common weight name
// ---     ---------------------------
// 100     Thin (Hairline)
// 200     Extra Light (Ultra Light)
// 300     Light
// 400     Normal
// 500     Medium
// 600     Semi Bold (Demi Bold)
// 700     Bold
// 800     Extra Bold (Ultra Bold)
// 900     Black (Heavy)
// ---     ---------------------------

@font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    src: url("/assets/fonts/proxima-nova.woff") format("woff");
  }
  
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 700;
    src: url("/assets/fonts/proxima-nova-bold.woff") format("woff");
  }
  
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 600;
    src: url("/assets/fonts/proxima-nova-semibold.woff") format("woff");
  }
  
  @font-face {
    font-family: "Proxima Nova";
    font-style: normal;
    font-weight: 400;
    font-style: italic;
    src: url("/assets/fonts/proxima-nova-it.woff") format("woff");
  }