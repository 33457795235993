html {
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: $bgWhite;
}

body {
  min-height: 100%;
  min-width: 320px;
  margin: 0 auto;
  @include marginCollapseFix;
  background-color: $bgWhite;

  font: {
    family: $primaryFont;
    size: rem(16px);
  }

  line-height: 1.5;

  &.noscroll {
    overflow: hidden;
  }

  // Style the cookie alert to use full screen
  div[role='alertdialog'] {
    left: 0;
  }
}

app-root {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}

main {
  max-width: $siteWidth;
  margin: 0 auto;
  width: 100%;

  @include media('>=desktop-navigation') {
    padding: 0 32px;
  }

  // @include media("<desktop-navigation") {
  //   padding: 0 24px
  // }
}

app-footer {
  margin-top: auto;
}

.vh,
.visuallyhidden {
  @include visuallyhidden;
}

.hideInMobile {
  @include media('<=md') {
    display: none !important;
  }
}

h1 {
  font-size: 1.25em;
}

h2 {
  font-size: 1.125em;
}

h3 {
  font-size: 1em;
}

.loadingSpinner {
  border-radius: 50%;
  animation: loadingScreenSpin 2s linear infinite;
  margin: 0 auto;
  height: 70px;
  width: 70px;
  border: 4px solid #fc0;
  border-bottom: 4px solid #e8e8e8;

  @keyframes loadingScreenSpin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
