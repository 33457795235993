$header-height-mobile: 75px;
$header-height-desktop: 100px;

.header {
  background-color: white;
  position: relative;
  border-bottom: 4px solid $primaryColor;
  z-index: 4;

  @include media('>=desktop-navigation') {
    height: $header-height-desktop;
  }

  @include media('<desktop-navigation') {
    height: $header-height-mobile;
  }

  &__logo {
    display: block;
    width: 100px;
    position: absolute;
    left: 32px;
    top: 32px;

    @include media('<desktop-navigation') {
      left: 24px;
      top: 24px;
      width: 70px;
    }
  }

  > div {
    max-width: $siteWidth;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: auto;

    @include media('>=desktop-navigation') {
      padding: 0 32px 0 182px;
      height: 100px;
    }

    @include media('<desktop-navigation') {
      margin: 0 0 24px 0px;
      padding: 0 18px 0 80px;
      height: 75px;
    }
  }
}

.transition {
  transition: 0.4s;
}

.visible {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  margin-top: 0;
}

.hidden {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  @include media('>=desktop-navigation') {
    margin-top: -$header-height-desktop - 2px;
  }

  @include media('<desktop-navigation') {
    margin-top: -$header-height-mobile - 2px;
  }
}

.marginFiller {
  @include media('>=desktop-navigation') {
    margin-top: $header-height-desktop;
  }

  @include media('<desktop-navigation') {
    margin-top: $header-height-mobile;
  }
}

.hamburger {
  display: block;
  position: relative;
  width: 44px;
  height: 38px;
  background: {
    color: transparent;
    image: url('/assets/icons/bars.svg');
    position: 50% 50%;
    repeat: no-repeat;
    size: auto 20px;
  }
  appearance: none;
  line-height: 1;
  border: none;
  outline: none;

  @include media('>=desktop-navigation') {
    display: none;
  }
}

.mainNav {
  flex: 1 1 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include media('<desktop-navigation') {
    justify-content: flex-end;
  }
}
