// This file may NOT output any CSS.

$black: (
  100: rgb(0, 0, 0),
  90: rgb(0, 0, 0, 0.9),
  80: rgba(0, 0, 0, 0.8),
  60: rgba(0, 0, 0, 0.6),
  30: rgba(0, 0, 0, 0.3),
  'overlay': rgba(0, 0, 0, 0.32),
);

$monochrome: (
  100: #f3f4f8,
  300: #d7d8db,
  500: #9d9ea3,
  700: #808285,
  900: #45484a,
);

// Default, Hertz
$primary: (
  50: #fffbeb,
  100: #fff7d6,
  200: #ffefaf,
  300: #ffde56,
  500: #ffcc00,
  700: #fdbf17,
);

$secondary: (
  50: #f1f5ff,
  100: #cfdbff,
  200: #b1c5ff,
  300: #3968fd,
  500: #355ad8,
  700: #2e4cb4,
);

$success: (
  50: #e1faf2,
  100: #caf2e6,
  200: #9eddcb,
  300: #0ab284,
  500: #088764,
  700: #036248,
);

$error: (
  50: #ffe8e9,
  100: #fbd0d2,
  200: #ffa6aa,
  300: #f03c42,
  500: #cd2026,
  700: #b4151a,
);

$warning: (
  50: #fff7e7,
  100: #feecca,
  200: #ffe0a6,
  300: #fcb022,
  500: #e68938,
  700: #bb5b06,
);

$info: (
  50: #e5f4ff,
  100: #d6ecfe,
  200: #afdcff,
  300: #56affc,
  500: #4695d9,
  700: #3873a7,
);

// ===--- Colors ---===

$primaryColor: map_get($primary, 500); /* Default brand color */
$primaryBtnTextColor: map_get($black, 100); /* Default brand color */

$bgWhite: #fff;
$bgGray: map_get($monochrome, 100);
$bgYellow: map_get($primary, 100);

$borderGray: map_get($monochrome, 300);
$borderYellowBrown: #d6b34a;
$darkGray: map_get($monochrome, 900);
$includedGray: map_get($monochrome, 100);

$cPrimaryText: map_get($black, 80);
$cPrimaryTextWhite: #ffffff;
$cYellowTextBrown: #866504;

$blue: map_get($secondary, 500);
$errorRed: map_get($error, 500);

// ===--- Sizes ---===

$siteWidth: 1030px;

$blockPadding: 20px;
$blockPaddingMobile: 16px;
$columnWidth: 64px;
$gutterWidth: 20px;

// ===--- Fonts ---===

$primaryFont: 'Proxima Nova', Helvetica, Arial, sans-serif; /* Default brand font */

// ===--- Breakpoints map ---===
// Usage: @include media( '>=tablet-small', '<desktop' ) { ... }

$breakpoints: (
  'sm': 400px,
  // 5 cols
  'smmd': 496px,
  //
  'tablet-navigation': 576px,
  //
  'desktop-navigation': 820px,
  'md': 820px,
  // 10 cols
  'lg': 904px,
  // 11 cols
  'xl': $siteWidth,
) !default;

// ===--- Bootstrap Grid Variables ---===

$grid-columns: 12 !default;
$grid-gutter-width: 20px !default;
$enable-grid-classes: true !default;

$grid-breakpoints: (
  xs: 0,
  sm: map_get($breakpoints, 'sm'),
  md: map_get($breakpoints, 'md'),
  lg: map_get($breakpoints, 'lg'),
  xl: map_get($breakpoints, 'xl'),
) !default;

$container-max-widths: (
  sm: 484px,
  md: 736px,
  lg: 820px,
  xl: 1008px,
) !default;
