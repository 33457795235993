// This file may NOT output any CSS.


@mixin listReset {
    list-style-type: none;
    margin: 0;
    padding: 0;
}

@mixin visuallyhidden {
    position: absolute;
    overflow: hidden;
    clip: rect(0 0 0 0);
    height: 1px;
    width: 1px;
    margin: -1px;
    padding: 0;
    border: 0;
}

@mixin marginCollapseFix {
    &:before,
    &:after {
        content: ' ';
        display: table;
    }
}

@mixin hover-focus-active( $self: false ) {
    @if $self {
        &,
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    } @else {
        &:hover,
        &:active,
        &:focus {
            @content;
        }
    }
}
