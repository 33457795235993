.flagIcon {
    background-size: contain;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    border-radius: 2px;
}

.flagIcon--inline {
    position: relative;
    display: inline-block;
    width: 27px;
    line-height: 20px;
}

.flagIcon:before {
    content: "\00a0";
}

.flagIcon--squared {
    width: 20px;
}

.flagIcon--ad {
    background-image: url("/assets/flags/4x3/ad.svg");
}

.flagIcon--ad.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ad.svg");
}

.flagIcon--ae {
    background-image: url("/assets/flags/4x3/ae.svg");
}

.flagIcon--ae.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ae.svg");
}

.flagIcon--af {
    background-image: url("/assets/flags/4x3/af.svg");
}

.flagIcon--af.flagIcon--squared {
    background-image: url("/assets/flags/1x1/af.svg");
}

.flagIcon--ag {
    background-image: url("/assets/flags/4x3/ag.svg");
}

.flagIcon--ag.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ag.svg");
}

.flagIcon--ai {
    background-image: url("/assets/flags/4x3/ai.svg");
}

.flagIcon--ai.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ai.svg");
}

.flagIcon--al {
    background-image: url("/assets/flags/4x3/al.svg");
}

.flagIcon--al.flagIcon--squared {
    background-image: url("/assets/flags/1x1/al.svg");
}

.flagIcon--am {
    background-image: url("/assets/flags/4x3/am.svg");
}

.flagIcon--am.flagIcon--squared {
    background-image: url("/assets/flags/1x1/am.svg");
}

.flagIcon--ao {
    background-image: url("/assets/flags/4x3/ao.svg");
}

.flagIcon--ao.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ao.svg");
}

.flagIcon--aq {
    background-image: url("/assets/flags/4x3/aq.svg");
}

.flagIcon--aq.flagIcon--squared {
    background-image: url("/assets/flags/1x1/aq.svg");
}

.flagIcon--ar {
    background-image: url("/assets/flags/4x3/ar.svg");
}

.flagIcon--ar.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ar.svg");
}

.flagIcon--as {
    background-image: url("/assets/flags/4x3/as.svg");
}

.flagIcon--as.flagIcon--squared {
    background-image: url("/assets/flags/1x1/as.svg");
}

.flagIcon--at {
    background-image: url("/assets/flags/4x3/at.svg");
}

.flagIcon--at.flagIcon--squared {
    background-image: url("/assets/flags/1x1/at.svg");
}

.flagIcon--au {
    background-image: url("/assets/flags/4x3/au.svg");
}

.flagIcon--au.flagIcon--squared {
    background-image: url("/assets/flags/1x1/au.svg");
}

.flagIcon--aw {
    background-image: url("/assets/flags/4x3/aw.svg");
}

.flagIcon--aw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/aw.svg");
}

.flagIcon--ax {
    background-image: url("/assets/flags/4x3/ax.svg");
}

.flagIcon--ax.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ax.svg");
}

.flagIcon--az {
    background-image: url("/assets/flags/4x3/az.svg");
}

.flagIcon--az.flagIcon--squared {
    background-image: url("/assets/flags/1x1/az.svg");
}

.flagIcon--ba {
    background-image: url("/assets/flags/4x3/ba.svg");
}

.flagIcon--ba.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ba.svg");
}

.flagIcon--bb {
    background-image: url("/assets/flags/4x3/bb.svg");
}

.flagIcon--bb.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bb.svg");
}

.flagIcon--bd {
    background-image: url("/assets/flags/4x3/bd.svg");
}

.flagIcon--bd.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bd.svg");
}

.flagIcon--be {
    background-image: url("/assets/flags/4x3/be.svg");
}

.flagIcon--be.flagIcon--squared {
    background-image: url("/assets/flags/1x1/be.svg");
}

.flagIcon--bf {
    background-image: url("/assets/flags/4x3/bf.svg");
}

.flagIcon--bf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bf.svg");
}

.flagIcon--bg {
    background-image: url("/assets/flags/4x3/bg.svg");
}

.flagIcon--bg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bg.svg");
}

.flagIcon--bh {
    background-image: url("/assets/flags/4x3/bh.svg");
}

.flagIcon--bh.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bh.svg");
}

.flagIcon--bi {
    background-image: url("/assets/flags/4x3/bi.svg");
}

.flagIcon--bi.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bi.svg");
}

.flagIcon--bj {
    background-image: url("/assets/flags/4x3/bj.svg");
}

.flagIcon--bj.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bj.svg");
}

.flagIcon--bl {
    background-image: url("/assets/flags/4x3/bl.svg");
}

.flagIcon--bl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bl.svg");
}

.flagIcon--bm {
    background-image: url("/assets/flags/4x3/bm.svg");
}

.flagIcon--bm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bm.svg");
}

.flagIcon--bn {
    background-image: url("/assets/flags/4x3/bn.svg");
}

.flagIcon--bn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bn.svg");
}

.flagIcon--bo {
    background-image: url("/assets/flags/4x3/bo.svg");
}

.flagIcon--bo.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bo.svg");
}

.flagIcon--bq {
    background-image: url("/assets/flags/4x3/bq.svg");
}

.flagIcon--bq.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bq.svg");
}

.flagIcon--br {
    background-image: url("/assets/flags/4x3/br.svg");
}

.flagIcon--br.flagIcon--squared {
    background-image: url("/assets/flags/1x1/br.svg");
}

.flagIcon--bs {
    background-image: url("/assets/flags/4x3/bs.svg");
}

.flagIcon--bs.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bs.svg");
}

.flagIcon--bt {
    background-image: url("/assets/flags/4x3/bt.svg");
}

.flagIcon--bt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bt.svg");
}

.flagIcon--bv {
    background-image: url("/assets/flags/4x3/bv.svg");
}

.flagIcon--bv.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bv.svg");
}

.flagIcon--bw {
    background-image: url("/assets/flags/4x3/bw.svg");
}

.flagIcon--bw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bw.svg");
}

.flagIcon--by {
    background-image: url("/assets/flags/4x3/by.svg");
}

.flagIcon--by.flagIcon--squared {
    background-image: url("/assets/flags/1x1/by.svg");
}

.flagIcon--bz {
    background-image: url("/assets/flags/4x3/bz.svg");
}

.flagIcon--bz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/bz.svg");
}

.flagIcon--ca {
    background-image: url("/assets/flags/4x3/ca.svg");
}

.flagIcon--ca.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ca.svg");
}

.flagIcon--cc {
    background-image: url("/assets/flags/4x3/cc.svg");
}

.flagIcon--cc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cc.svg");
}

.flagIcon--cd {
    background-image: url("/assets/flags/4x3/cd.svg");
}

.flagIcon--cd.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cd.svg");
}

.flagIcon--cf {
    background-image: url("/assets/flags/4x3/cf.svg");
}

.flagIcon--cf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cf.svg");
}

.flagIcon--cg {
    background-image: url("/assets/flags/4x3/cg.svg");
}

.flagIcon--cg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cg.svg");
}

.flagIcon--ch {
    background-image: url("/assets/flags/4x3/ch.svg");
}

.flagIcon--ch.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ch.svg");
}

.flagIcon--ci {
    background-image: url("/assets/flags/4x3/ci.svg");
}

.flagIcon--ci.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ci.svg");
}

.flagIcon--ck {
    background-image: url("/assets/flags/4x3/ck.svg");
}

.flagIcon--ck.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ck.svg");
}

.flagIcon--cl {
    background-image: url("/assets/flags/4x3/cl.svg");
}

.flagIcon--cl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cl.svg");
}

.flagIcon--cm {
    background-image: url("/assets/flags/4x3/cm.svg");
}

.flagIcon--cm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cm.svg");
}

.flagIcon--cn {
    background-image: url("/assets/flags/4x3/cn.svg");
}

.flagIcon--cn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cn.svg");
}

.flagIcon--co {
    background-image: url("/assets/flags/4x3/co.svg");
}

.flagIcon--co.flagIcon--squared {
    background-image: url("/assets/flags/1x1/co.svg");
}

.flagIcon--cr {
    background-image: url("/assets/flags/4x3/cr.svg");
}

.flagIcon--cr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cr.svg");
}

.flagIcon--cu {
    background-image: url("/assets/flags/4x3/cu.svg");
}

.flagIcon--cu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cu.svg");
}

.flagIcon--cv {
    background-image: url("/assets/flags/4x3/cv.svg");
}

.flagIcon--cv.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cv.svg");
}

.flagIcon--cw {
    background-image: url("/assets/flags/4x3/cw.svg");
}

.flagIcon--cw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cw.svg");
}

.flagIcon--cx {
    background-image: url("/assets/flags/4x3/cx.svg");
}

.flagIcon--cx.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cx.svg");
}

.flagIcon--cy {
    background-image: url("/assets/flags/4x3/cy.svg");
}

.flagIcon--cy.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cy.svg");
}

.flagIcon--cz {
    background-image: url("/assets/flags/4x3/cz.svg");
}

.flagIcon--cz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/cz.svg");
}

.flagIcon--de {
    background-image: url("/assets/flags/4x3/de.svg");
}

.flagIcon--de.flagIcon--squared {
    background-image: url("/assets/flags/1x1/de.svg");
}

.flagIcon--dj {
    background-image: url("/assets/flags/4x3/dj.svg");
}

.flagIcon--dj.flagIcon--squared {
    background-image: url("/assets/flags/1x1/dj.svg");
}

.flagIcon--dk {
    background-image: url("/assets/flags/4x3/dk.svg");
}

.flagIcon--dk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/dk.svg");
}

.flagIcon--dm {
    background-image: url("/assets/flags/4x3/dm.svg");
}

.flagIcon--dm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/dm.svg");
}

.flagIcon--do {
    background-image: url("/assets/flags/4x3/do.svg");
}

.flagIcon--do.flagIcon--squared {
    background-image: url("/assets/flags/1x1/do.svg");
}

.flagIcon--dz {
    background-image: url("/assets/flags/4x3/dz.svg");
}

.flagIcon--dz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/dz.svg");
}

.flagIcon--ec {
    background-image: url("/assets/flags/4x3/ec.svg");
}

.flagIcon--ec.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ec.svg");
}

.flagIcon--ee {
    background-image: url("/assets/flags/4x3/ee.svg");
}

.flagIcon--ee.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ee.svg");
}

.flagIcon--eg {
    background-image: url("/assets/flags/4x3/eg.svg");
}

.flagIcon--eg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/eg.svg");
}

.flagIcon--eh {
    background-image: url("/assets/flags/4x3/eh.svg");
}

.flagIcon--eh.flagIcon--squared {
    background-image: url("/assets/flags/1x1/eh.svg");
}

.flagIcon--er {
    background-image: url("/assets/flags/4x3/er.svg");
}

.flagIcon--er.flagIcon--squared {
    background-image: url("/assets/flags/1x1/er.svg");
}

.flagIcon--es {
    background-image: url("/assets/flags/4x3/es.svg");
}

.flagIcon--es.flagIcon--squared {
    background-image: url("/assets/flags/1x1/es.svg");
}

.flagIcon--et {
    background-image: url("/assets/flags/4x3/et.svg");
}

.flagIcon--et.flagIcon--squared {
    background-image: url("/assets/flags/1x1/et.svg");
}

.flagIcon--fi {
    background-image: url("/assets/flags/4x3/fi.svg");
}

.flagIcon--fi.flagIcon--squared {
    background-image: url("/assets/flags/1x1/fi.svg");
}

.flagIcon--fj {
    background-image: url("/assets/flags/4x3/fj.svg");
}

.flagIcon--fj.flagIcon--squared {
    background-image: url("/assets/flags/1x1/fj.svg");
}

.flagIcon--fk {
    background-image: url("/assets/flags/4x3/fk.svg");
}

.flagIcon--fk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/fk.svg");
}

.flagIcon--fm {
    background-image: url("/assets/flags/4x3/fm.svg");
}

.flagIcon--fm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/fm.svg");
}

.flagIcon--fo {
    background-image: url("/assets/flags/4x3/fo.svg");
}

.flagIcon--fo.flagIcon--squared {
    background-image: url("/assets/flags/1x1/fo.svg");
}

.flagIcon--fr {
    background-image: url("/assets/flags/4x3/fr.svg");
}

.flagIcon--fr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/fr.svg");
}

.flagIcon--ga {
    background-image: url("/assets/flags/4x3/ga.svg");
}

.flagIcon--ga.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ga.svg");
}

.flagIcon--gb {
    background-image: url("/assets/flags/4x3/gb.svg");
}

.flagIcon--gb.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gb.svg");
}

.flagIcon--gd {
    background-image: url("/assets/flags/4x3/gd.svg");
}

.flagIcon--gd.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gd.svg");
}

.flagIcon--ge {
    background-image: url("/assets/flags/4x3/ge.svg");
}

.flagIcon--ge.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ge.svg");
}

.flagIcon--gf {
    background-image: url("/assets/flags/4x3/gf.svg");
}

.flagIcon--gf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gf.svg");
}

.flagIcon--gg {
    background-image: url("/assets/flags/4x3/gg.svg");
}

.flagIcon--gg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gg.svg");
}

.flagIcon--gh {
    background-image: url("/assets/flags/4x3/gh.svg");
}

.flagIcon--gh.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gh.svg");
}

.flagIcon--gi {
    background-image: url("/assets/flags/4x3/gi.svg");
}

.flagIcon--gi.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gi.svg");
}

.flagIcon--gl {
    background-image: url("/assets/flags/4x3/gl.svg");
}

.flagIcon--gl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gl.svg");
}

.flagIcon--gm {
    background-image: url("/assets/flags/4x3/gm.svg");
}

.flagIcon--gm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gm.svg");
}

.flagIcon--gn {
    background-image: url("/assets/flags/4x3/gn.svg");
}

.flagIcon--gn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gn.svg");
}

.flagIcon--gp {
    background-image: url("/assets/flags/4x3/gp.svg");
}

.flagIcon--gp.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gp.svg");
}

.flagIcon--gq {
    background-image: url("/assets/flags/4x3/gq.svg");
}

.flagIcon--gq.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gq.svg");
}

.flagIcon--gr {
    background-image: url("/assets/flags/4x3/gr.svg");
}

.flagIcon--gr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gr.svg");
}

.flagIcon--gs {
    background-image: url("/assets/flags/4x3/gs.svg");
}

.flagIcon--gs.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gs.svg");
}

.flagIcon--gt {
    background-image: url("/assets/flags/4x3/gt.svg");
}

.flagIcon--gt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gt.svg");
}

.flagIcon--gu {
    background-image: url("/assets/flags/4x3/gu.svg");
}

.flagIcon--gu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gu.svg");
}

.flagIcon--gw {
    background-image: url("/assets/flags/4x3/gw.svg");
}

.flagIcon--gw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gw.svg");
}

.flagIcon--gy {
    background-image: url("/assets/flags/4x3/gy.svg");
}

.flagIcon--gy.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gy.svg");
}

.flagIcon--hk {
    background-image: url("/assets/flags/4x3/hk.svg");
}

.flagIcon--hk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/hk.svg");
}

.flagIcon--hm {
    background-image: url("/assets/flags/4x3/hm.svg");
}

.flagIcon--hm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/hm.svg");
}

.flagIcon--hn {
    background-image: url("/assets/flags/4x3/hn.svg");
}

.flagIcon--hn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/hn.svg");
}

.flagIcon--hr {
    background-image: url("/assets/flags/4x3/hr.svg");
}

.flagIcon--hr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/hr.svg");
}

.flagIcon--ht {
    background-image: url("/assets/flags/4x3/ht.svg");
}

.flagIcon--ht.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ht.svg");
}

.flagIcon--hu {
    background-image: url("/assets/flags/4x3/hu.svg");
}

.flagIcon--hu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/hu.svg");
}

.flagIcon--id {
    background-image: url("/assets/flags/4x3/id.svg");
}

.flagIcon--id.flagIcon--squared {
    background-image: url("/assets/flags/1x1/id.svg");
}

.flagIcon--ie {
    background-image: url("/assets/flags/4x3/ie.svg");
}

.flagIcon--ie.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ie.svg");
}

.flagIcon--il {
    background-image: url("/assets/flags/4x3/il.svg");
}

.flagIcon--il.flagIcon--squared {
    background-image: url("/assets/flags/1x1/il.svg");
}

.flagIcon--im {
    background-image: url("/assets/flags/4x3/im.svg");
}

.flagIcon--im.flagIcon--squared {
    background-image: url("/assets/flags/1x1/im.svg");
}

.flagIcon--in {
    background-image: url("/assets/flags/4x3/in.svg");
}

.flagIcon--in.flagIcon--squared {
    background-image: url("/assets/flags/1x1/in.svg");
}

.flagIcon--io {
    background-image: url("/assets/flags/4x3/io.svg");
}

.flagIcon--io.flagIcon--squared {
    background-image: url("/assets/flags/1x1/io.svg");
}

.flagIcon--iq {
    background-image: url("/assets/flags/4x3/iq.svg");
}

.flagIcon--iq.flagIcon--squared {
    background-image: url("/assets/flags/1x1/iq.svg");
}

.flagIcon--ir {
    background-image: url("/assets/flags/4x3/ir.svg");
}

.flagIcon--ir.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ir.svg");
}

.flagIcon--is {
    background-image: url("/assets/flags/4x3/is.svg");
}

.flagIcon--is.flagIcon--squared {
    background-image: url("/assets/flags/1x1/is.svg");
}

.flagIcon--it {
    background-image: url("/assets/flags/4x3/it.svg");
}

.flagIcon--it.flagIcon--squared {
    background-image: url("/assets/flags/1x1/it.svg");
}

.flagIcon--je {
    background-image: url("/assets/flags/4x3/je.svg");
}

.flagIcon--je.flagIcon--squared {
    background-image: url("/assets/flags/1x1/je.svg");
}

.flagIcon--jm {
    background-image: url("/assets/flags/4x3/jm.svg");
}

.flagIcon--jm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/jm.svg");
}

.flagIcon--jo {
    background-image: url("/assets/flags/4x3/jo.svg");
}

.flagIcon--jo.flagIcon--squared {
    background-image: url("/assets/flags/1x1/jo.svg");
}

.flagIcon--jp {
    background-image: url("/assets/flags/4x3/jp.svg");
}

.flagIcon--jp.flagIcon--squared {
    background-image: url("/assets/flags/1x1/jp.svg");
}

.flagIcon--ke {
    background-image: url("/assets/flags/4x3/ke.svg");
}

.flagIcon--ke.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ke.svg");
}

.flagIcon--kg {
    background-image: url("/assets/flags/4x3/kg.svg");
}

.flagIcon--kg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kg.svg");
}

.flagIcon--kh {
    background-image: url("/assets/flags/4x3/kh.svg");
}

.flagIcon--kh.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kh.svg");
}

.flagIcon--ki {
    background-image: url("/assets/flags/4x3/ki.svg");
}

.flagIcon--ki.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ki.svg");
}

.flagIcon--km {
    background-image: url("/assets/flags/4x3/km.svg");
}

.flagIcon--km.flagIcon--squared {
    background-image: url("/assets/flags/1x1/km.svg");
}

.flagIcon--kn {
    background-image: url("/assets/flags/4x3/kn.svg");
}

.flagIcon--kn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kn.svg");
}

.flagIcon--kp {
    background-image: url("/assets/flags/4x3/kp.svg");
}

.flagIcon--kp.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kp.svg");
}

.flagIcon--kr {
    background-image: url("/assets/flags/4x3/kr.svg");
}

.flagIcon--kr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kr.svg");
}

.flagIcon--kw {
    background-image: url("/assets/flags/4x3/kw.svg");
}

.flagIcon--kw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kw.svg");
}

.flagIcon--ky {
    background-image: url("/assets/flags/4x3/ky.svg");
}

.flagIcon--ky.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ky.svg");
}

.flagIcon--kz {
    background-image: url("/assets/flags/4x3/kz.svg");
}

.flagIcon--kz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/kz.svg");
}

.flagIcon--la {
    background-image: url("/assets/flags/4x3/la.svg");
}

.flagIcon--la.flagIcon--squared {
    background-image: url("/assets/flags/1x1/la.svg");
}

.flagIcon--lb {
    background-image: url("/assets/flags/4x3/lb.svg");
}

.flagIcon--lb.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lb.svg");
}

.flagIcon--lc {
    background-image: url("/assets/flags/4x3/lc.svg");
}

.flagIcon--lc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lc.svg");
}

.flagIcon--li {
    background-image: url("/assets/flags/4x3/li.svg");
}

.flagIcon--li.flagIcon--squared {
    background-image: url("/assets/flags/1x1/li.svg");
}

.flagIcon--lk {
    background-image: url("/assets/flags/4x3/lk.svg");
}

.flagIcon--lk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lk.svg");
}

.flagIcon--lr {
    background-image: url("/assets/flags/4x3/lr.svg");
}

.flagIcon--lr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lr.svg");
}

.flagIcon--ls {
    background-image: url("/assets/flags/4x3/ls.svg");
}

.flagIcon--ls.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ls.svg");
}

.flagIcon--lt {
    background-image: url("/assets/flags/4x3/lt.svg");
}

.flagIcon--lt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lt.svg");
}

.flagIcon--lu {
    background-image: url("/assets/flags/4x3/lu.svg");
}

.flagIcon--lu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lu.svg");
}

.flagIcon--lv {
    background-image: url("/assets/flags/4x3/lv.svg");
}

.flagIcon--lv.flagIcon--squared {
    background-image: url("/assets/flags/1x1/lv.svg");
}

.flagIcon--ly {
    background-image: url("/assets/flags/4x3/ly.svg");
}

.flagIcon--ly.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ly.svg");
}

.flagIcon--ma {
    background-image: url("/assets/flags/4x3/ma.svg");
}

.flagIcon--ma.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ma.svg");
}

.flagIcon--mc {
    background-image: url("/assets/flags/4x3/mc.svg");
}

.flagIcon--mc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mc.svg");
}

.flagIcon--md {
    background-image: url("/assets/flags/4x3/md.svg");
}

.flagIcon--md.flagIcon--squared {
    background-image: url("/assets/flags/1x1/md.svg");
}

.flagIcon--me {
    background-image: url("/assets/flags/4x3/me.svg");
}

.flagIcon--me.flagIcon--squared {
    background-image: url("/assets/flags/1x1/me.svg");
}

.flagIcon--mf {
    background-image: url("/assets/flags/4x3/mf.svg");
}

.flagIcon--mf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mf.svg");
}

.flagIcon--mg {
    background-image: url("/assets/flags/4x3/mg.svg");
}

.flagIcon--mg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mg.svg");
}

.flagIcon--mh {
    background-image: url("/assets/flags/4x3/mh.svg");
}

.flagIcon--mh.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mh.svg");
}

.flagIcon--mk {
    background-image: url("/assets/flags/4x3/mk.svg");
}

.flagIcon--mk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mk.svg");
}

.flagIcon--ml {
    background-image: url("/assets/flags/4x3/ml.svg");
}

.flagIcon--ml.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ml.svg");
}

.flagIcon--mm {
    background-image: url("/assets/flags/4x3/mm.svg");
}

.flagIcon--mm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mm.svg");
}

.flagIcon--mn {
    background-image: url("/assets/flags/4x3/mn.svg");
}

.flagIcon--mn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mn.svg");
}

.flagIcon--mo {
    background-image: url("/assets/flags/4x3/mo.svg");
}

.flagIcon--mo.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mo.svg");
}

.flagIcon--mp {
    background-image: url("/assets/flags/4x3/mp.svg");
}

.flagIcon--mp.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mp.svg");
}

.flagIcon--mq {
    background-image: url("/assets/flags/4x3/mq.svg");
}

.flagIcon--mq.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mq.svg");
}

.flagIcon--mr {
    background-image: url("/assets/flags/4x3/mr.svg");
}

.flagIcon--mr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mr.svg");
}

.flagIcon--ms {
    background-image: url("/assets/flags/4x3/ms.svg");
}

.flagIcon--ms.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ms.svg");
}

.flagIcon--mt {
    background-image: url("/assets/flags/4x3/mt.svg");
}

.flagIcon--mt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mt.svg");
}

.flagIcon--mu {
    background-image: url("/assets/flags/4x3/mu.svg");
}

.flagIcon--mu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mu.svg");
}

.flagIcon--mv {
    background-image: url("/assets/flags/4x3/mv.svg");
}

.flagIcon--mv.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mv.svg");
}

.flagIcon--mw {
    background-image: url("/assets/flags/4x3/mw.svg");
}

.flagIcon--mw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mw.svg");
}

.flagIcon--mx {
    background-image: url("/assets/flags/4x3/mx.svg");
}

.flagIcon--mx.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mx.svg");
}

.flagIcon--my {
    background-image: url("/assets/flags/4x3/my.svg");
}

.flagIcon--my.flagIcon--squared {
    background-image: url("/assets/flags/1x1/my.svg");
}

.flagIcon--mz {
    background-image: url("/assets/flags/4x3/mz.svg");
}

.flagIcon--mz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/mz.svg");
}

.flagIcon--na {
    background-image: url("/assets/flags/4x3/na.svg");
}

.flagIcon--na.flagIcon--squared {
    background-image: url("/assets/flags/1x1/na.svg");
}

.flagIcon--nc {
    background-image: url("/assets/flags/4x3/nc.svg");
}

.flagIcon--nc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/nc.svg");
}

.flagIcon--ne {
    background-image: url("/assets/flags/4x3/ne.svg");
}

.flagIcon--ne.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ne.svg");
}

.flagIcon--nf {
    background-image: url("/assets/flags/4x3/nf.svg");
}

.flagIcon--nf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/nf.svg");
}

.flagIcon--ng {
    background-image: url("/assets/flags/4x3/ng.svg");
}

.flagIcon--ng.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ng.svg");
}

.flagIcon--ni {
    background-image: url("/assets/flags/4x3/ni.svg");
}

.flagIcon--ni.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ni.svg");
}

.flagIcon--nl {
    background-image: url("/assets/flags/4x3/nl.svg");
}

.flagIcon--nl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/nl.svg");
}

.flagIcon--no {
    background-image: url("/assets/flags/4x3/no.svg");
}

.flagIcon--no.flagIcon--squared {
    background-image: url("/assets/flags/1x1/no.svg");
}

.flagIcon--np {
    background-image: url("/assets/flags/4x3/np.svg");
}

.flagIcon--np.flagIcon--squared {
    background-image: url("/assets/flags/1x1/np.svg");
}

.flagIcon--nr {
    background-image: url("/assets/flags/4x3/nr.svg");
}

.flagIcon--nr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/nr.svg");
}

.flagIcon--nu {
    background-image: url("/assets/flags/4x3/nu.svg");
}

.flagIcon--nu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/nu.svg");
}

.flagIcon--nz {
    background-image: url("/assets/flags/4x3/nz.svg");
}

.flagIcon--nz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/nz.svg");
}

.flagIcon--om {
    background-image: url("/assets/flags/4x3/om.svg");
}

.flagIcon--om.flagIcon--squared {
    background-image: url("/assets/flags/1x1/om.svg");
}

.flagIcon--pa {
    background-image: url("/assets/flags/4x3/pa.svg");
}

.flagIcon--pa.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pa.svg");
}

.flagIcon--pe {
    background-image: url("/assets/flags/4x3/pe.svg");
}

.flagIcon--pe.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pe.svg");
}

.flagIcon--pf {
    background-image: url("/assets/flags/4x3/pf.svg");
}

.flagIcon--pf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pf.svg");
}

.flagIcon--pg {
    background-image: url("/assets/flags/4x3/pg.svg");
}

.flagIcon--pg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pg.svg");
}

.flagIcon--ph {
    background-image: url("/assets/flags/4x3/ph.svg");
}

.flagIcon--ph.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ph.svg");
}

.flagIcon--pk {
    background-image: url("/assets/flags/4x3/pk.svg");
}

.flagIcon--pk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pk.svg");
}

.flagIcon--pl {
    background-image: url("/assets/flags/4x3/pl.svg");
}

.flagIcon--pl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pl.svg");
}

.flagIcon--pm {
    background-image: url("/assets/flags/4x3/pm.svg");
}

.flagIcon--pm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pm.svg");
}

.flagIcon--pn {
    background-image: url("/assets/flags/4x3/pn.svg");
}

.flagIcon--pn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pn.svg");
}

.flagIcon--pr {
    background-image: url("/assets/flags/4x3/pr.svg");
}

.flagIcon--pr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pr.svg");
}

.flagIcon--ps {
    background-image: url("/assets/flags/4x3/ps.svg");
}

.flagIcon--ps.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ps.svg");
}

.flagIcon--pt {
    background-image: url("/assets/flags/4x3/pt.svg");
}

.flagIcon--pt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pt.svg");
}

.flagIcon--pw {
    background-image: url("/assets/flags/4x3/pw.svg");
}

.flagIcon--pw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/pw.svg");
}

.flagIcon--py {
    background-image: url("/assets/flags/4x3/py.svg");
}

.flagIcon--py.flagIcon--squared {
    background-image: url("/assets/flags/1x1/py.svg");
}

.flagIcon--qa {
    background-image: url("/assets/flags/4x3/qa.svg");
}

.flagIcon--qa.flagIcon--squared {
    background-image: url("/assets/flags/1x1/qa.svg");
}

.flagIcon--re {
    background-image: url("/assets/flags/4x3/re.svg");
}

.flagIcon--re.flagIcon--squared {
    background-image: url("/assets/flags/1x1/re.svg");
}

.flagIcon--ro {
    background-image: url("/assets/flags/4x3/ro.svg");
}

.flagIcon--ro.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ro.svg");
}

.flagIcon--rs {
    background-image: url("/assets/flags/4x3/rs.svg");
}

.flagIcon--rs.flagIcon--squared {
    background-image: url("/assets/flags/1x1/rs.svg");
}

.flagIcon--ru {
    background-image: url("/assets/flags/4x3/ru.svg");
}

.flagIcon--ru.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ru.svg");
}

.flagIcon--rw {
    background-image: url("/assets/flags/4x3/rw.svg");
}

.flagIcon--rw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/rw.svg");
}

.flagIcon--sa {
    background-image: url("/assets/flags/4x3/sa.svg");
}

.flagIcon--sa.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sa.svg");
}

.flagIcon--sb {
    background-image: url("/assets/flags/4x3/sb.svg");
}

.flagIcon--sb.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sb.svg");
}

.flagIcon--sc {
    background-image: url("/assets/flags/4x3/sc.svg");
}

.flagIcon--sc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sc.svg");
}

.flagIcon--sd {
    background-image: url("/assets/flags/4x3/sd.svg");
}

.flagIcon--sd.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sd.svg");
}

.flagIcon--se {
    background-image: url("/assets/flags/4x3/se.svg");
}

.flagIcon--se.flagIcon--squared {
    background-image: url("/assets/flags/1x1/se.svg");
}

.flagIcon--sg {
    background-image: url("/assets/flags/4x3/sg.svg");
}

.flagIcon--sg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sg.svg");
}

.flagIcon--sh {
    background-image: url("/assets/flags/4x3/sh.svg");
}

.flagIcon--sh.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sh.svg");
}

.flagIcon--si {
    background-image: url("/assets/flags/4x3/si.svg");
}

.flagIcon--si.flagIcon--squared {
    background-image: url("/assets/flags/1x1/si.svg");
}

.flagIcon--sj {
    background-image: url("/assets/flags/4x3/sj.svg");
}

.flagIcon--sj.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sj.svg");
}

.flagIcon--sk {
    background-image: url("/assets/flags/4x3/sk.svg");
}

.flagIcon--sk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sk.svg");
}

.flagIcon--sl {
    background-image: url("/assets/flags/4x3/sl.svg");
}

.flagIcon--sl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sl.svg");
}

.flagIcon--sm {
    background-image: url("/assets/flags/4x3/sm.svg");
}

.flagIcon--sm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sm.svg");
}

.flagIcon--sn {
    background-image: url("/assets/flags/4x3/sn.svg");
}

.flagIcon--sn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sn.svg");
}

.flagIcon--so {
    background-image: url("/assets/flags/4x3/so.svg");
}

.flagIcon--so.flagIcon--squared {
    background-image: url("/assets/flags/1x1/so.svg");
}

.flagIcon--sr {
    background-image: url("/assets/flags/4x3/sr.svg");
}

.flagIcon--sr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sr.svg");
}

.flagIcon--ss {
    background-image: url("/assets/flags/4x3/ss.svg");
}

.flagIcon--ss.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ss.svg");
}

.flagIcon--st {
    background-image: url("/assets/flags/4x3/st.svg");
}

.flagIcon--st.flagIcon--squared {
    background-image: url("/assets/flags/1x1/st.svg");
}

.flagIcon--sv {
    background-image: url("/assets/flags/4x3/sv.svg");
}

.flagIcon--sv.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sv.svg");
}

.flagIcon--sx {
    background-image: url("/assets/flags/4x3/sx.svg");
}

.flagIcon--sx.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sx.svg");
}

.flagIcon--sy {
    background-image: url("/assets/flags/4x3/sy.svg");
}

.flagIcon--sy.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sy.svg");
}

.flagIcon--sz {
    background-image: url("/assets/flags/4x3/sz.svg");
}

.flagIcon--sz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/sz.svg");
}

.flagIcon--tc {
    background-image: url("/assets/flags/4x3/tc.svg");
}

.flagIcon--tc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tc.svg");
}

.flagIcon--td {
    background-image: url("/assets/flags/4x3/td.svg");
}

.flagIcon--td.flagIcon--squared {
    background-image: url("/assets/flags/1x1/td.svg");
}

.flagIcon--tf {
    background-image: url("/assets/flags/4x3/tf.svg");
}

.flagIcon--tf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tf.svg");
}

.flagIcon--tg {
    background-image: url("/assets/flags/4x3/tg.svg");
}

.flagIcon--tg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tg.svg");
}

.flagIcon--th {
    background-image: url("/assets/flags/4x3/th.svg");
}

.flagIcon--th.flagIcon--squared {
    background-image: url("/assets/flags/1x1/th.svg");
}

.flagIcon--tj {
    background-image: url("/assets/flags/4x3/tj.svg");
}

.flagIcon--tj.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tj.svg");
}

.flagIcon--tk {
    background-image: url("/assets/flags/4x3/tk.svg");
}

.flagIcon--tk.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tk.svg");
}

.flagIcon--tl {
    background-image: url("/assets/flags/4x3/tl.svg");
}

.flagIcon--tl.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tl.svg");
}

.flagIcon--tm {
    background-image: url("/assets/flags/4x3/tm.svg");
}

.flagIcon--tm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tm.svg");
}

.flagIcon--tn {
    background-image: url("/assets/flags/4x3/tn.svg");
}

.flagIcon--tn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tn.svg");
}

.flagIcon--to {
    background-image: url("/assets/flags/4x3/to.svg");
}

.flagIcon--to.flagIcon--squared {
    background-image: url("/assets/flags/1x1/to.svg");
}

.flagIcon--tr {
    background-image: url("/assets/flags/4x3/tr.svg");
}

.flagIcon--tr.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tr.svg");
}

.flagIcon--tt {
    background-image: url("/assets/flags/4x3/tt.svg");
}

.flagIcon--tt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tt.svg");
}

.flagIcon--tv {
    background-image: url("/assets/flags/4x3/tv.svg");
}

.flagIcon--tv.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tv.svg");
}

.flagIcon--tw {
    background-image: url("/assets/flags/4x3/tw.svg");
}

.flagIcon--tw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tw.svg");
}

.flagIcon--tz {
    background-image: url("/assets/flags/4x3/tz.svg");
}

.flagIcon--tz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/tz.svg");
}

.flagIcon--ua {
    background-image: url("/assets/flags/4x3/ua.svg");
}

.flagIcon--ua.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ua.svg");
}

.flagIcon--ug {
    background-image: url("/assets/flags/4x3/ug.svg");
}

.flagIcon--ug.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ug.svg");
}

.flagIcon--um {
    background-image: url("/assets/flags/4x3/um.svg");
}

.flagIcon--um.flagIcon--squared {
    background-image: url("/assets/flags/1x1/um.svg");
}

.flagIcon--us {
    background-image: url("/assets/flags/4x3/us.svg");
}

.flagIcon--us.flagIcon--squared {
    background-image: url("/assets/flags/1x1/us.svg");
}

.flagIcon--uy {
    background-image: url("/assets/flags/4x3/uy.svg");
}

.flagIcon--uy.flagIcon--squared {
    background-image: url("/assets/flags/1x1/uy.svg");
}

.flagIcon--uz {
    background-image: url("/assets/flags/4x3/uz.svg");
}

.flagIcon--uz.flagIcon--squared {
    background-image: url("/assets/flags/1x1/uz.svg");
}

.flagIcon--va {
    background-image: url("/assets/flags/4x3/va.svg");
}

.flagIcon--va.flagIcon--squared {
    background-image: url("/assets/flags/1x1/va.svg");
}

.flagIcon--vc {
    background-image: url("/assets/flags/4x3/vc.svg");
}

.flagIcon--vc.flagIcon--squared {
    background-image: url("/assets/flags/1x1/vc.svg");
}

.flagIcon--ve {
    background-image: url("/assets/flags/4x3/ve.svg");
}

.flagIcon--ve.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ve.svg");
}

.flagIcon--vg {
    background-image: url("/assets/flags/4x3/vg.svg");
}

.flagIcon--vg.flagIcon--squared {
    background-image: url("/assets/flags/1x1/vg.svg");
}

.flagIcon--vi {
    background-image: url("/assets/flags/4x3/vi.svg");
}

.flagIcon--vi.flagIcon--squared {
    background-image: url("/assets/flags/1x1/vi.svg");
}

.flagIcon--vn {
    background-image: url("/assets/flags/4x3/vn.svg");
}

.flagIcon--vn.flagIcon--squared {
    background-image: url("/assets/flags/1x1/vn.svg");
}

.flagIcon--vu {
    background-image: url("/assets/flags/4x3/vu.svg");
}

.flagIcon--vu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/vu.svg");
}

.flagIcon--wf {
    background-image: url("/assets/flags/4x3/wf.svg");
}

.flagIcon--wf.flagIcon--squared {
    background-image: url("/assets/flags/1x1/wf.svg");
}

.flagIcon--ws {
    background-image: url("/assets/flags/4x3/ws.svg");
}

.flagIcon--ws.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ws.svg");
}

.flagIcon--ye {
    background-image: url("/assets/flags/4x3/ye.svg");
}

.flagIcon--ye.flagIcon--squared {
    background-image: url("/assets/flags/1x1/ye.svg");
}

.flagIcon--yt {
    background-image: url("/assets/flags/4x3/yt.svg");
}

.flagIcon--yt.flagIcon--squared {
    background-image: url("/assets/flags/1x1/yt.svg");
}

.flagIcon--za {
    background-image: url("/assets/flags/4x3/za.svg");
}

.flagIcon--za.flagIcon--squared {
    background-image: url("/assets/flags/1x1/za.svg");
}

.flagIcon--zm {
    background-image: url("/assets/flags/4x3/zm.svg");
}

.flagIcon--zm.flagIcon--squared {
    background-image: url("/assets/flags/1x1/zm.svg");
}

.flagIcon--zw {
    background-image: url("/assets/flags/4x3/zw.svg");
}

.flagIcon--zw.flagIcon--squared {
    background-image: url("/assets/flags/1x1/zw.svg");
}

.flagIcon--es-ct {
    background-image: url("/assets/flags/4x3/es-ct.svg");
}

.flagIcon--es-ct.flagIcon--squared {
    background-image: url("/assets/flags/1x1/es-ct.svg");
}

.flagIcon--eu {
    background-image: url("/assets/flags/4x3/eu.svg");
}

.flagIcon--eu.flagIcon--squared {
    background-image: url("/assets/flags/1x1/eu.svg");
}

.flagIcon--gb-eng {
    background-image: url("/assets/flags/4x3/gb-eng.svg");
}

.flagIcon--gb-eng.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gb-eng.svg");
}

.flagIcon--gb-nir {
    background-image: url("/assets/flags/4x3/gb-nir.svg");
}

.flagIcon--gb-nir.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gb-nir.svg");
}

.flagIcon--gb-sct {
    background-image: url("/assets/flags/4x3/gb-sct.svg");
}

.flagIcon--gb-sct.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gb-sct.svg");
}

.flagIcon--gb-wls {
    background-image: url("/assets/flags/4x3/gb-wls.svg");
}

.flagIcon--gb-wls.flagIcon--squared {
    background-image: url("/assets/flags/1x1/gb-wls.svg");
}

.flagIcon--un {
    background-image: url("/assets/flags/4x3/un.svg");
}

.flagIcon--un.flagIcon--squared {
    background-image: url("/assets/flags/1x1/un.svg");
}
