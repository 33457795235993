@import 'shared';

/* MODAL STYLES
-------------------------------*/
.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 6;

  &__body {
    position: absolute;
    width: 600px;
    max-width: 100%;
    height: 450px;
    max-height: 100%;
    overflow-y: auto;
    border-radius: 6px;
    height: fit-content;
    margin: auto;
    display: block;
    padding: 24px;
    color: black;
    background-color: #ffffff;
    opacity: 1;
    right: 0;
    left: 0;
    top: 0;
    bottom: 0;
    z-index: 1000;

    &--error {
      width: 480px;
    }

    &__button {
      color: black;
    }

    &__content {
      text-align: left;
      color: black;
      font-weight: 400;
      font-size: rem(14px);
      line-height: 150%;

      ul {
        margin: 0;
        padding: 0px 24px;
        font-size: large;
      }

      p {
        line-height: 1.5;
        margin: 12px 0px;
      }

      p:first-child {
        margin: 2 0 2rem;
      }

      h1 {
        font-size: rem(20px);
        margin: 0;
        text-align: center;
        line-height: 26px;
        font-weight: 700;
      }

      h2 {
        font-size: 20px;
        margin: 0;
        text-align: center;
        line-height: 130%;
        font-weight: 700;
      }

      h3 {
        font-size: 16px;
        line-height: 24px;
        font-weight: 600;
        color: map_get($black, 80);
        margin: 0;
      }

      hr {
        max-width: 44px;
        height: 4px;
        background-color: map_get($primary, 500);
        border: none;
        margin: 24px auto;
      }
    }
  }
}

@include media('<desktop-navigation') {
  .modal {
    &__body {
      box-shadow: 0px 0px 80px 0px rgba(0, 0, 0, 0.8);
      width: 90%;
      max-height: 90%;
      left: 50%;
      transform: translateX(-50%);

      /* Arrow down
            &::after {
                content: '';
                position: absolute;
                bottom: -23px;
                left: calc(50% - 16px);
                width: 0;
                height: 0;
                border-style: solid;
                border-width: 24px 16px 0 16px;
                border-color: $darkGray transparent transparent transparent;
            }*/
    }
  }

  .modal-open {
    /* body overflow is hidden to hide main scrollbar when modal window is open i mobile view*/
    overflow: hidden;
  }
}
