@mixin btnBase {
  border-radius: 4px;
  padding: 8px 24px;
  line-height: 24px;
  font-size: rem(16px);
  font-weight: 700;
  border: none;
  cursor: pointer;
  min-width: 100%;
  align-items: center;
  display: flex;
  justify-content: center;
  margin: auto;
  text-decoration: none;
  @include media('>=375px') {
    min-width: 280px;
  }
  > svg {
    height: 18px;
    margin-right: 13px;
  }
}

button {
  &:focus-visible {
    outline: 2px dashed map_get($secondary, 500);
  }
  &[disabled] {
    cursor: default;
  }
}

input {
  &[disabled] {
    cursor: default;
  }
}

.btn__modal {
  @include btnBase;
  border: 2px solid black;
  text-transform: uppercase;
  color: black;
  margin: 0;
  margin-top: 24px;
  min-width: none !important;
  width: stretch;
  background-color: transparent;
  > svg {
    fill: #ffffff;
  }
  &:not([disabled]) {
    &:hover {
      background-color: map_get($monochrome, 700);
    }
    &:active {
      background-color: map_get($monochrome, 300);
      color: map_get($monochrome, 900);
      > svg {
        fill: map_get($monochrome, 900);
      }
    }
    &:focus-visible {
      outline: 2px dashed map_get($secondary, 200);
    }
  }
  &[disabled] {
    border: 2px solid map_get($monochrome, 500);
    background-color: transparent;
    color: map_get($monochrome, 300);
    > svg {
      fill: map_get($monochrome, 300);
    }
  }
}

.btn__primary {
  @include btnBase;
  color: $primaryBtnTextColor;
  background-color: $primaryColor;
  &[disabled] {
    border: none;
    background-color: map_get($monochrome, 300);
    color: map_get($monochrome, 900);
    > svg {
      fill: map_get($monochrome, 900);
    }
  }
  &--outlined {
    @include btnBase;
    border: 2px solid #000000;
    color: #000000;
    background-color: transparent;
    > svg {
      fill: #000000;
    }
    &:not([disabled]) {
      &:hover {
        background-color: map_get($monochrome, 100);
      }
      &:active {
        background-color: map_get($monochrome, 300);
      }
    }
    &[disabled] {
      border: 2px solid map_get($monochrome, 900);
      background-color: map_get($monochrome, 300);
      color: map_get($monochrome, 900);
      > svg {
        fill: map_get($monochrome, 900);
      }
    }
  }
  &--inverted {
    @include btnBase;
    border: 2px solid #ffffff;
    color: #ffffff;
    background-color: transparent;
    > svg {
      fill: #ffffff;
    }
    &:not([disabled]) {
      &:hover {
        background-color: map_get($monochrome, 700);
      }
      &:active {
        background-color: map_get($monochrome, 300);
        color: map_get($monochrome, 900);
        > svg {
          fill: map_get($monochrome, 900);
        }
      }
      &:focus-visible {
        outline: 2px dashed map_get($secondary, 200);
      }
    }
    &[disabled] {
      border: 2px solid map_get($monochrome, 500);
      background-color: transparent;
      color: map_get($monochrome, 300);
      > svg {
        fill: map_get($monochrome, 300);
      }
    }
  }
}

.btn__secondary {
  @include btnBase;
  color: #000000;
  background-color: transparent;
  border: 2px solid #000000;
  &[disabled] {
    border: none;
    background-color: map_get($monochrome, 300);
    color: map_get($monochrome, 900);
    > svg {
      fill: map_get($monochrome, 900);
    }
  }
  &:not([disabled]) {
    &:hover {
      border: 2px solid transparent;
      background-color: map_get($secondary, 300);
      color: #ffffff;
      > svg {
        fill: #ffffff;
      }
    }
    &:active {
      border: 2px solid transparent;
      background-color: map_get($secondary, 700);
      color: #ffffff;
      > svg {
        fill: #ffffff;
      }
    }
  }
  &--outlined {
    @include btnBase;
    border: 2px solid map_get($secondary, 500);
    color: map_get($secondary, 500);
    background-color: transparent;
    > svg {
      fill: map_get($secondary, 500);
    }
    &:not([disabled]) {
      &:hover {
        background-color: map_get($secondary, 100);
      }
      &:active {
        background-color: transparent;
      }
    }
  }
  &--inverted {
    @include btnBase;
    border: 2px solid #ffffff;
    color: #ffffff;
    background-color: transparent;
    > svg {
      fill: #ffffff;
    }
    &:not([disabled]) {
      &:hover {
        background-color: map_get($monochrome, 700);
      }
      &:active {
        background-color: map_get($monochrome, 300);
        color: map_get($monochrome, 900);
        > svg {
          fill: map_get($monochrome, 900);
        }
      }
      &:focus-visible {
        outline: 2px dashed map_get($secondary, 200);
      }
    }
    &[disabled] {
      border: 2px solid map_get($monochrome, 500);
      background-color: transparent;
      color: map_get($monochrome, 300);
      > svg {
        fill: map_get($monochrome, 300);
      }
    }
  }
}

.btn__link {
  @include btnBase;
  font-size: rem(14px);
  color: map_get($secondary, 500);
  background-color: transparent;
  border: 2px solid transparent;
  text-decoration: none;
  &--text {
    cursor: pointer;
    font-weight: 700;
    color: map_get($secondary, 500);
    text-decoration: none;
    font-size: rem(14px);
    background-color: transparent;
    border: none;

    &:hover {
      color: map_get($secondary, 300);
    }
    &:active {
      color: map_get($secondary, 700);
    }
  }
  > svg {
    fill: map_get($secondary, 500);
  }
  &:not([disabled]) {
    &:hover {
      border: 2px solid transparent;
      background-color: transparent;
      color: map_get($secondary, 300);
      > svg {
        fill: map_get($secondary, 300);
      }
    }
    &:active {
      border: 2px solid transparent;
      background-color: transparent;
      color: map_get($secondary, 700);
      > svg {
        fill: map_get($secondary, 700);
      }
    }
  }
  &[disabled] {
    border: 2px solid transparent;
    background-color: transparent;
    color: map_get($monochrome, 500);
    > svg {
      fill: map_get($monochrome, 500);
    }
  }
}

.btn__secondaryColor {
  color: map_get($secondary, 200);
  &:hover {
    border: 2px solid transparent;
    background-color: transparent;
    color: map_get($secondary, 100) !important;
    > svg {
      fill: map_get($secondary, 100) !important;
    }
  }
  &:active {
    border: 2px solid transparent;
    background-color: transparent;
    color: map_get($secondary, 300) !important;
    > svg {
      fill: map_get($secondary, 300) !important;
    }
  }
}

.btn__md {
  padding: 13px 24px;
}

.btn__help {
  font-weight: 600;
  text-decoration: underline;
  min-width: min-content;
}

.uppercase {
  text-transform: uppercase;
}

.btn__edit {
  display: block;
  width: 24px;
  height: 24px;
  cursor: pointer;
  background: {
    image: url('/assets/icons/edit_blue.svg');
    position: right center;
    repeat: no-repeat;
    size: auto 17px;
  }
  float: right;
}

.btn__toggleIcon {
  &--small {
    color: map_get($secondary, 500);
    text-decoration: none;
    font-size: rem(14px);
    padding-right: 16px;
    font-weight: 700;

    background: {
      image: url('/assets/icons/arrow_right.svg');
      position: right center;
      repeat: no-repeat;
      size: auto 9px;
    }
    background-position-y: 4px;

    &:hover {
      text-decoration: underline;
    }
  }
}
