.block {
  padding: $blockPadding 0;
  margin: 10px 0;
  @include marginCollapseFix;

  &--nopadding {
    padding: 0;
  }

  &--nomargin {
    padding: 0;
  }

  &--gray {
    background-color: $bgGray;
  }

  &--yellow {
    background-color: $bgYellow;
    color: $cYellowTextBrown;
  }

  &--border {
    border-color: $borderYellowBrown;
    border: 1px solid transparent;
    border-radius: 4px;
  }

  &__body {
    margin: 0 auto;

    @for $i from 3 through 10 {
      &--max-col-#{$i} {
        max-width: columns($i);
      }
    }

    &--medium {
      max-width: columns(6);
    }

    &--small {
      max-width: columns(5);
    }
  }

  &--border-top-max-md {
    @include media('<=md') {
      border-top: 1px solid $borderGray;
    }
  }
}

.container {
  @include media('<smmd') {
    max-width: calc(100% - #{$blockPaddingMobile * 2 - $gutterWidth});
  }
}

.container-fluid {
  @include media('<desktop-navigation') {
    max-width: calc(100% - #{$blockPaddingMobile * 2 - $gutterWidth});
  }

  @include media('>=desktop-navigation') {
    max-width: calc(100% - #{$blockPadding * 2 - $gutterWidth});
  }
}

.col {
  &--center-text {
    text-align: center;
  }

  &--right-text {
    text-align: right;
  }
}

.col--border-right-md {
  @include media('>=md') {
    border-right: 1px solid $borderGray;
  }
}

.col--border-left-md {
  @include media('>=md') {
    position: relative;

    &:after {
      content: '';
      display: block;
      position: absolute;
      top: 0;
      left: -1px;
      bottom: 0;
      border-left: 1px solid $borderGray;
    }
  }
}

.col--border-right-yellow-md {
  @include media('>=md') {
    border-right: 1px solid $primaryColor;
  }
}

.form {
  &__block {
    max-width: 360px;
    margin: 0 auto;

    &--border {
      border-bottom: 1px solid $borderGray;
    }

    &__finland {
      max-width: 530px !important;
    }
  }

  &__row {
    margin: 0.8rem 0;
    display: flex;
    align-items: center;

    &__headingCol {
      width: 66%;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }

  &__errorRow {
    display: flex;
    align-items: center;
    margin: -12px 0 -2px 0;

    .form__error {
      padding-left: 6px;
    }
  }

  &__label {
    width: 34%;
    display: inline-block;
    text-align: right;
    padding-right: 10px;
  }
}

.d {
  &-none {
    display: none;
  }
  &-block {
    display: block;
  }
  &-sm {
    &-none {
      @include media('>=sm') {
        display: none;
      }
    }
    &-block {
      @include media('>=sm') {
        display: block;
      }
    }
  }
  &-md {
    &-none {
      @include media('>=md') {
        display: none;
      }
    }
    &-block {
      @include media('>=md') {
        display: block;
      }
    }
  }
}
