/* For use in src/lib/core/theming/_palette.scss */
$md-hertzyellow: (
    50 : #fff9e0,
    100 : #fff0b3,
    200 : #ffe680,
    300 : #ffdb4d,
    400 : #ffd426,
    500 : #ffcc00,
    600 : #ffc700,
    700 : #ffc000,
    800 : #ffb900,
    900 : #ffad00,
    A100 : #ffffff,
    A200 : #fffaf2,
    A400 : #ffe8bf,
    A700 : #ffdfa6,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #000000,
        700 : #000000,
        800 : #000000,
        900 : #000000,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-hertzwarn: (
    50 : #f9e1e4,
    100 : #f1b3bb,
    200 : #e8818d,
    300 : #de4e5f,
    400 : #d7283d,
    500 : #d0021b,
    600 : #cb0218,
    700 : #c40114,
    800 : #be0110,
    900 : #b30108,
    A100 : #ffdcdd,
    A200 : #ffa9aa,
    A400 : #ff7678,
    A700 : #ff5d5f,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$md-hertzblack: (
    50 : #e7e7e7,
    100 : #c3c3c3,
    200 : #9b9b9b,
    300 : #727272,
    400 : #545454,
    500 : #363636,
    600 : #303030,
    700 : #292929,
    800 : #222222,
    900 : #161616,
    A100 : #ef6f6f,
    A200 : #ea4141,
    A400 : #f80000,
    A700 : #df0000,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #ffffff,
        A400 : #ffffff,
        A700 : #ffffff,
    )
);

$md-hertzblue: (
    50 : #e6f1ff,
    100 : #c1ddff,
    200 : #98c6ff,
    300 : #6fafff,
    400 : #509eff,
    500 : #318dff,
    600 : #2c85ff,
    700 : #257aff,
    800 : #1f70ff,
    900 : #135dff,
    A100 : #ffffff,
    A200 : #fafbff,
    A400 : #c7d6ff,
    A700 : #adc4ff,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

