input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
input[type='number'] {
  -moz-appearance: textfield;
}

.inputCheckbox {
  appearance: none;
  background-color: white;
  padding: 7px;
  border: 2px solid $darkGray;
  border-radius: 2px;
  position: relative;
  margin-right: 10px;

  &--included {
    background: url('../../../assets/icons/check.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 16px;
    height: 14px;
    border: none;
    background-position: center;
  }

  &:active {
    // box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
  }

  &:checked {
    background: url('../../../assets/icons/check-square_blue.svg');
    background-repeat: no-repeat;
    background-size: 100%;
    width: 18px;
    height: 18px;
    border: none;
    background-position: center;

    // background-color: $darkGray;

    // &:after {
    //     content: '\2714';
    //     font-size: 14px;
    //     position: absolute;
    //     top: 0px;
    //     left: 2px;
    //     color: white;
    // }

    // &:active {
    //     box-shadow: 0 1px 2px rgba(0,0,0,0.05), inset 0px 1px 3px rgba(0,0,0,0.1);
    // }
  }
}

.inputText {
  appearance: none;
  border-radius: 3px;
  border: 1px solid $borderGray;
  padding: 0 5px;
  height: 29px;
  line-height: 29px;
  font-size: 16px;
  font-weight: 400;
  color: $cPrimaryText;

  &--large {
    width: 66%;
  }

  &--medium {
    width: 63%;
  }

  &--small {
    width: 35%;
  }

  &--enabled {
    background: white;
    font-size: 16px;
    border: 1px solid $borderGray;
  }

  &--disabled {
    background: $bgGray;
    border: none;
    font-size: rem(13px);
    text-overflow: ellipsis;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid $errorRed;
    box-shadow: 0 0 6px 0 $errorRed;
  }
}

// När någon bygger date pickern får detta appliceras på whatever element hen väljer att använda.
.inputCalendar {
  appearance: none;
  border-radius: 3px;
  border: 1px solid $borderGray;
  padding: 0 34px 0 5px;
  height: 29px;
  color: $cPrimaryText;
  background: {
    image: url('../../../assets/icons/calendar.svg');
    position: right 14px top 6px;
    repeat: no-repeat;
    size: auto 14px;
  }

  &--large {
    width: 66%;
  }

  &.ng-invalid.ng-touched {
    border: 1px solid $errorRed;
    box-shadow: 0 0 6px 0 $errorRed;
  }

  // Fix for IE6-10 & edge
  @media screen\9, screen\0 {
    background-position: 142% 55%, -130% 54%;
  }
}

select {
  background: {
    color: white;
    image: url('../../../assets/icons/sort.svg');
    repeat: no-repeat;
    size: 10px auto;
    position: center right 10px;
  }
}
