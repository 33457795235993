$textMarginBottom: 10px;

.helperWrapper {
  display: flex;
  flex-direction: row;
  &__block {
    width: 50%;
    position: relative;
  }
}

.className {
  color: $blue;
  padding-left: 20px;
  font-size: 12px;
  font-style: none;
  text-transform: none;
  position: absolute;
  right: 20px;
  font-weight: 700;
  background: white;
}

.page {
  &__heading {
    font-size: rem(20px);
    color: $cPrimaryText;
    font-weight: 700;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
  &__subHeading {
    font-size: rem(16px);
    color: #4a4a4a;
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    text-align: center;
  }
}

.car {
  &__model {
    color: $cPrimaryText;
    font-size: rem(16px);
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 0;
    margin-bottom: 0;
    text-align: left;
  }

  &__orSimilar {
    color: rgba(0, 0, 0, 0.6);
    font-size: rem(16px);
    font-weight: 400;
    margin-top: 0;
    margin-bottom: 0;
    text-transform: lowercase;
    text-align: left;
    line-height: 150%;
  }

  &__class {
    color: map_get($monochrome, 900);
    font-size: rem(12px);
    display: flex;
    flex-wrap: wrap;
    text-align: center;
    justify-content: center;
    font-weight: 400;
    margin: 0;
  }
}

.section {
  &__label {
    font-size: rem(14px); /* rem(13px)? */
    font-weight: 400;
    color: $cPrimaryText;
    letter-spacing: -0.34px;
  }

  &__heading {
    color: $cPrimaryText;
    font-size: rem(20px);
    font-weight: 700;
  }

  &__date {
    font-size: rem(14px);
    font-weight: 700;
    color: $cPrimaryText;
  }

  &__title {
    font-size: rem(14px);
    font-weight: 700;
    color: $cPrimaryText;
  }

  &__subtitle {
    font-size: rem(16px);
    font-weight: 700;
    color: rgba(0, 0, 0, 0.8);
  }

  &__body {
    font-size: rem(14px);
    font-weight: 400;
    color: map_get($black, 60);
    &--bold {
      font-weight: 700;
    }

    &--small {
      font-size: rem(14px);
    }

    &--noMargins {
      margin: 0;
    }
  }

  &__included {
    font-weight: 700;
    color: $cPrimaryText;
    text-transform: uppercase;
    font-size: rem(14px);
    border-radius: 14px;
    background-color: #d8d8d8;
    display: inline;
    padding: 2px 10px;
    line-height: 1;
  }

  &__price {
    font-size: rem(14px);
    font-weight: 700;
    text-transform: uppercase;
    color: $cPrimaryText;
  }
}

.finalCost {
  &__heading {
    font-size: rem(12px);
    font-weight: 700;
    text-transform: uppercase;
    color: $cPrimaryText;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__body {
    font-size: rem(12px);
    font-weight: 400;
    color: $cPrimaryText;
    margin-top: 0;
    margin-bottom: 0;
  }

  &__price {
    font-size: rem(14px);
    font-weight: 700;
    text-transform: uppercase;
    color: $cPrimaryText;
    margin-top: 0;
    margin-bottom: 0;
  }
}

.form {
  &__label {
    font-size: rem(13px);
    font-weight: 400;
    color: $cPrimaryText;
    letter-spacing: -0.31px;
  }

  &__error {
    font-size: rem(12px);
    font-weight: 400;
    color: $errorRed;
    margin-top: rem(-2px);
    margin-bottom: 0;
  }

  &__helper {
    font-size: rem(12px);
    font-weight: 400;
    color: map-get($black, 60);
    margin-top: rem(-2px);
    margin-bottom: 0;
  }
}

.heading {
  font-size: rem(14px);
  font-weight: 700;
  color: $cPrimaryText;
  margin-top: 0;
  margin-bottom: 10px;
  text-align: center;
}

.paragraph {
  font-size: rem(14px);
  font-weight: 400;
  color: $cPrimaryText;
  margin-top: 0;
  margin-bottom: $textMarginBottom;
  text-align: center;
  letter-spacing: -0.34px;
  &--bold {
    font-weight: 700;
    letter-spacing: -0.34px;
  }
  &--alignLeft {
    text-align: left;
  }
}

.modal {
  &__paragraph {
    color: black;
    font-weight: 400;
    font-size: rem(14px);
    line-height: 150%;
    &--center {
      text-align: center;
    }
  }
}

.navigation {
  &__desktop {
    &__link {
      color: $cPrimaryText;
      text-decoration: none;
      font-size: rem(13px);
      &:hover {
        text-decoration: underline;
      }
    }
  }
  &__mobile {
    &__link {
      display: block;
      color: $cPrimaryTextWhite;
      font-weight: 700;
      font-size: rem(13px);
      text-decoration: none;
      margin: 0;
      padding: 15px 15px 15px 42px;
      line-height: 1;

      .mobileNav__item--stepper & {
        padding-left: 72px;
      }

      &--grey {
        color: #9b9b9b;
      }
    }
  }
}

.stepper {
  &__item {
    &__link {
      font: {
        size: rem(10px);
        weight: 400;
      }
      color: $cPrimaryText;
      text-decoration: none;
      pointer-events: none;

      .stepper__item--selected & {
        font-weight: 700;
        color: black;
      }
      .stepper__item--done & {
        color: black;
        pointer-events: all;
        cursor: pointer;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}

.text-center {
  text-align: center;
}

.closex {
  position: relative;
  top: -20px;
  right: 10px;
  color: inherit;
  float: right;
  font-size: 21px;
  font-weight: 700;
  opacity: 0.2;
  text-decoration: none;
  line-height: 1;
}

/*
  https://projects.invisionapp.com/share/XDJHOJQHGS3#/screens/299301676

  Page Heading // My upcoming car rental
  Page SubHeading // Save time at pick up and do an Online Check-in

  Car Model // VOLVO XC40
  or similar // or similar
  Car Class // Standard Class

  Section Label // Pick up / Drop off
  Section Heading // INSURANCES
  Section Date // Wed, September 26, 2018 02:00 PM
  Section Title // Collision Damage Waiver
  Section Body // Oslo Airport Gardemoen
  Section Body--grey // Credit card registration will be made outside
  Section CostPerDay // cost/day
  Section Included // (INCLUDED)
  Section Included--noBackground // INCLUDED
  Section Price // 30 NOK

  Final Price Heading // TOTAL ESTIMATED EXTRA CHARGES
  Final Price Body // Extra charges are paid by the customer at desk.
  Final Price Price // 0 NOK

  Form Label // Name
  Form Error // Missing driving license number

  Heading // Do an Online Check-in
  Paragraph // This is an example text, where i try to convince
  Paragraph--bold // Complete your check-in in just five steps!

  Modal Heading // WHAT IS AN ONLINE CHECKIN?
  Modal Paragraph // This infotext will tell you what an online

  Navigation Desktop // My booking / About Online Check-in

  Navigation Mobile // My booking / Additional services
  Navigation Mobile--grey // About Hertz Online Check-in

 */
